import React, { useState, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { IMaskInput } from "react-imask";

import { DataContext } from '~components/DataContext';
import Logout from '~components/Logout/Logout';
import SearchInput from '~components/Search/Search';
import Loader from '~components/Loader/Loader';
import Button from "~components/Button/Button";
import ClientItem from '~components/ClientItem';
import Modal from '~components/Modal';

import getApiData from '~utils/getApiData';
import useCheckUserGroup from '~utils/useCheckUserGroup';
import axios from "~utils/axiosSetup";
import useConfirm from '~utils/useConfirm';
import { useDebounce } from "~utils/useDebounce.js";

import icon from '~img/sidebar/icon.svg';
import successIcon from "~img/icons/check.svg";

function Clients() {
    const navigate = useNavigate();
    const formData = {}
    const isAdmin = useCheckUserGroup('Администратор');
    const {user} = useContext(DataContext);
    const [loading, setLoading] = useState(false);
    const [modalLoading, setModalLoading] = useState(false);
    const [searchTerm, setSearchTerm] = useState('');
    const [searchResults, setSearchResults] = useState([]);
    const [storeProfile, setStoreProfile] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [showModalSuccess, setShowModalSuccess] = useState(false);
    const [userData, setUserData] = useState(null);
    const [dataProcessing, setDataProcessing] = useState(false);
    const [deleteProcessing, setDeleteProcessing] = useState(false);
    const { confirm, confirmComponent } = useConfirm();
    const [formState, setFormState] = useState({
        email: '',
        storeEmail: '',
        emailStatus: null,
        formSend: false,
        error: null,
        formSubject: 'Новые данные для рекламно-аналитической платформы',
        deleteData: false,
        deleteDataError: false,
    });

    useEffect(() => {
        if (userData) {
            setFormState(prevState => ({
                ...prevState,
                email: userData.email || '',
                storeEmail: userData.email || ''
            }));
        }
    
        if (storeProfile) {
            setSearchResults(storeProfile.filter(client =>
                client.client_name.toLowerCase().includes(searchTerm.toLowerCase())
            ));
        }
    }, [userData, storeProfile, searchTerm]);

    const fileUploaded = async e => {
        let file = (e.target.files[0])
        const formData = new FormData();
        formData.append('file', file);
        formData.append('profile', e.target.id);

        try {
            await axios.post(`${window.location.origin.replace('3000', '8000')}/api/change-logo/`, formData)
        } catch (error) {
            alert('Ошибка изменения данных.');
        } finally {
            const url = isAdmin ? 'profile/all' : `profile/all?pm_id=${user.id}`
            getApiData(url, setStoreProfile, setLoading, navigate)
        }
    }

    const fileDelete = async (profile_id) => {

        const isConfirmed = await confirm("Вы уверены, что хотите удалить логотип?");

        try {
            if (isConfirmed) {
                await axios.delete(`${window.location.origin.replace('3000', '8000')}/api/change-logo/${profile_id}`,)
            }
        } catch (error) {
            alert('Ошибка изменения данных.');
        } finally {
            const url = isAdmin ? 'profile/all' : `profile/all?pm_id=${user.id}`
            getApiData(url, setStoreProfile, setLoading, navigate)
        }
    }

    useEffect(() => {
        const fetchData = async () => {
            if (user) {
                try {
                    setLoading(true);
                    const url = isAdmin ? 'profile/all' : `profile/all?pm_id=${user.id}`;
                    await getApiData(url, setStoreProfile, setLoading, navigate);
                } catch (error) {
                    console.error("Ошибка при загрузке данных:", error);
                } finally {
                    setLoading(false);
                }
            } else {
                setLoading(false);
            }
        };
        fetchData();
    }, [user, navigate, setStoreProfile, isAdmin]);

    const getClientProfile = (currentProfile) => {
        sessionStorage.setItem('currentProfile', currentProfile);
        navigate('/')
    };

    storeProfile.sort(function (a, b) {
        return a.client_name.toLowerCase().localeCompare(b.client_name.toLowerCase());
    });

    const openModal = async (user_id) => {
        try {
            const response = await axios.get(`${window.location.origin.replace('3000', '8000')}/api/auth_data/${user_id}`);

            if (response.status === 200) {
                if (response.data.email) {
                    setFormState(prevState => ({
                        ...prevState,
                        formSend: true,
                    }));
                }
                setUserData(response.data);
                setTimeout(() => {
                    setShowModal(true);
                }, 25)

            } else {
                console.error('Ошибка при загрузке информации профиля клиента');
            }
        } catch (error) {
            console.error(error);
        }
    };

    const onClose = () => {
        setFormState(prevState => ({
            ...prevState,
            formSend: false,
            emailStatus: null,
            error: null,
            deleteData: false,
        }));
        setShowModal(false);
        setShowModalSuccess(false);
    };

    const findEmail = async (email_data, user_id) => {
        try {
            const response = await axios.get(`${window.location.origin.replace('3000', '8000')}/api/find_email/${user_id}/${email_data}`);
            if (response.status === 200 && response.data.success === false) {
                setFormState(prevState => ({
                    ...prevState,
                    formSend: false,
                    emailStatus: response.data.status,
                }));
            } else {
                setFormState(prevState => ({
                    ...prevState,
                    formSend: true,
                    emailStatus: response.data.status,
                    error: null
                }));
            }
        } catch (error) {
            console.error(error);
            setFormState(prevState => ({
                ...prevState,
                formSend: false,
                emailStatus: error,
            }));
        }
    };

    const debouncedFindEmail = useDebounce(findEmail, 300);

    const handleChange = (e, user_id) => {
        const email = e.target.value;
        setFormState(prevState => ({
            ...prevState,
            email: email,
        }));

        if (email) {
            debouncedFindEmail(email, user_id);
        }
    };

    if (loading || !user) {
        return (
            <div className="content__wrapper content__wrapper_loader">
                <Loader/>
            </div>
        );
    }

    const handleDeleteData = async (e, user_id) => {

        setDeleteProcessing(true)

        const isConfirmed = await confirm("Вы уверены, что хотите удалить клиента?");
        if (isConfirmed) {
            try {
                const response = await axios.post(`${window.location.origin.replace('3000', '8000')}/api/auth_data_delete/${user_id}`);

                if (response.status === 200) {
                    setFormState(prevState => ({
                        ...prevState,
                        formSend: true,
                        deleteData: response.data.status,
                        email: '',
                        storeEmail: '',
                    }));
                    setDeleteProcessing(false)

                    setTimeout(() => {
                        setFormState(prevState => ({
                            ...prevState,
                            deleteData: false,
                        }));
                    }, 5000)
                }

            } catch (error) {
                setFormState(prevState => ({
                    ...prevState,
                    deleteDataError: error.response.data.error ? error.response.data.error : 'Ошибка сохранения данных'
                }));
                setDeleteProcessing(false)
                console.error(error.response.data.error ? error.response.data.error : error);
            }
        } else {
            setDeleteProcessing(false)
        }
    }

    const handleSubmit = async (e, user_id) => {
        e.preventDefault();
        setDataProcessing(true)

        if (formState.storeEmail !== '') {
            setFormState(prevState => ({
                ...prevState,
                formSubject: 'Обновление данных для рекламно-аналитической платформы'
            }));
        }

        const data = {
            site_url: window.location.hostname,
            email: formState.email,
            subject: formState.formSubject,
            updateData: formState.storeEmail !== '',
            ...formData
        };

        try {
            const response = await axios.post(`${window.location.origin.replace('3000', '8000')}/api/auth_data_change/${user_id}`, data);
            setModalLoading(true)
            if (response.status === 200) {
                setFormState(prevState => ({
                    ...prevState,
                    error: response.data.error,
                }));
                setModalLoading(false)
                setShowModal(false);
                setDataProcessing(false)

                if (response.data.success === true) {
                    setShowModalSuccess(true);
                    setTimeout(() => {
                        setShowModalSuccess(false)
                    }, 3000)
                }

            } else {
                setDataProcessing(false)
                setFormState(prevState => ({
                    ...prevState,
                    error: 'Ошибка сохранения данных',
                }));
            }
        } catch (error) {
            setFormState(prevState => ({
                ...prevState,
                error: error.response.data.error ? error.response.data.error : 'Ошибка сохранения данных',
            }));
            console.error(error.response.data.error ? error.response.data.error : error);
            setDataProcessing(false)
        }
    };

    if (storeProfile) {
        return (
            <>
                <div className="home__wrapper">
                    <div className="sidebar__wrapper sidebar__wrapper_admin">
                        <Logout hide={true}/>
                        <div className="sidebar__icon">
                            <img alt="icon" src={icon}/>
                        </div>
                        <div className="sidebar__user">
                            Синергиум
                        </div>
                    </div>
                    <div className="content__wrapper">
                        <div className="clients__wrapper">
                            <SearchInput
                                searchTerm={searchTerm}
                                onSearchChange={(e) => setSearchTerm(e.target.value)}
                                placeholder="Найти"
                            />
                            <div className="clients__title bold">
                                Личные кабинеты клиник
                            </div>
                            <div className="clients__items">
                                {(storeProfile.length === 0 &&
                                    <div className="clients__item">Вам ещё не назначили ни одного клиента, обратитесь к
                                        своему тимлиду!</div>
                                )}
                                {searchTerm === '' ?
                                    storeProfile.map((profile) => (
                                        <ClientItem
                                            key={profile.id}
                                            profile={profile}
                                            getClientProfile={getClientProfile}
                                            openModal={openModal}
                                            fileChange={{"uploaded": fileUploaded, "delete": fileDelete}}                                       
                                        />
                                    )) :
                                    searchResults.map((profile) => (
                                        <ClientItem
                                            key={profile.id}
                                            profile={profile}
                                            getClientProfile={getClientProfile}
                                            openModal={openModal}
                                            fileChange={{"uploaded": fileUploaded, "delete": fileDelete}}
                                        />
                                    ))
                                }
                            </div>
                        </div>
                    </div>
                </div>
                {confirmComponent}
                <Modal
                    className={"modal-mail"}
                    showModal={showModal}
                    modalLoading={modalLoading}
                    onClose={onClose}
                >
                    <div className="modal__title">Редактировать данные для входа</div>
                    <form className="form" onSubmit={(e) => handleSubmit(e, userData.id)}>
                        <div className="form__group form__group_name">
                            {(formState.deleteData || formState.deleteDataError) && (
                                <div className={`status__block ${formState.deleteData && 'status__block_success'}`}>
                                    {formState.deleteData ? (
                                        <span>{formState.deleteData}</span>
                                    ) : formState.deleteDataError && (
                                        <span>{formState.deleteDataError}</span>
                                    )}
                                </div>
                            )}
                            <label className="label">Почта клиента:</label>
                            <div className='input-wrapper'>
                                <IMaskInput
                                    className="input bold"
                                    mask={/^\S*@?\S*$/}
                                    name="email"
                                    value={formState.email || ''}
                                    onChange={(e) => handleChange(e, userData.id)}
                                />
                                <span className={`status-label ${formState.error || (formState.emailStatus && !formState.formSend) ? 'error' : formState.emailStatus && formState.formSend ? 'success' : ''}`}>
                                    {formState.error ? (
                                        <span>{formState.error}</span>
                                    ) : (
                                        formState.emailStatus && <span>{formState.emailStatus}</span>
                                    )}
                                </span>
                            </div>
                        </div>
                        <div className="modal__text">
                            Пароль генерируется автоматически при отправке данных клиенту.
                        </div>

                        <div className="modal__button-wrapper">
                            <Button
                                    variant="main"
                                    className="button_save"
                                    type="submit"
                                    disabled={!formState.formSend}
                                    loading={dataProcessing}
                            >
                                {formState.storeEmail !== '' ? "Обновить данные клиента" : "Отправить доступы"}
                            </Button>

                            {formState.storeEmail &&
                                <Button
                                    variant="main"
                                    className="button_delete-data"
                                    type="button"
                                    onClick={(e) => handleDeleteData(e, userData.id)}
                                    loading={deleteProcessing}
                                >
                                    Удалить доступ
                                </Button>
                            }
                        </div>
                    </form>
                </Modal>
                <Modal
                    className={"modal-success"}
                    showModal={showModalSuccess}
                    modalLoading={modalLoading}
                    onClose={onClose}
                >
                    <div className="success-icon-wrapper">
                        <img className="success-icon" src={successIcon} alt="Успешно"/>
                    </div>
                </Modal>
            </>
        );
    }
}

export default Clients;
