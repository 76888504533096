import React, {useState, useEffect, useContext} from 'react';
import {useNavigate, Outlet} from 'react-router-dom';
import Logout from '~components/Logout/Logout';
import getApiData from '~utils/getApiData';
import Menu from "~components/Menu/Menu";
import {DataContext} from '~components/DataContext';
import getMediaFile from "~utils/getMediaFile";
import {changeBg} from "~utils/avatar";
import Loader from '~components/Loader/Loader';

function Home() {
    const navigate = useNavigate();
    const {data, setData, setProfile, setService, user} = useContext(DataContext);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchData = async () => {
            if (user) {

                let profile_id;
                if (sessionStorage.getItem("currentProfile")) {
                    profile_id = sessionStorage.getItem("currentProfile");
                } else {
                    profile_id = user.username;
                }

                try {
                    // Устанавливаем загрузку перед выполнением запросов
                    setLoading(true);

                    // Выполняем запросы
                    await getApiData("services/", setService, setLoading, navigate);
                    await getApiData(`profile/${profile_id}`, (data) => {
                        setProfile(data[0]);
                    }, setLoading, navigate);
                    await getApiData(`client_data/${profile_id}`, (data) => {
                        setData(data[0]);
                    }, setLoading, navigate);
                } catch (error) {
                    console.error("Ошибка при загрузке данных:", error);
                } finally {
                    setLoading(false);
                }
            } else {
                setLoading(false);
            }
        };

        fetchData();

    }, [user, navigate, setService, setProfile, setData]);

    if (loading || !user) {
        return (
            <div className="home__wrapper">
                    <div className="sidebar__wrapper">
                        <div className="sidebar__container">
                            <Logout/>
                        </div>
                    </div>
                <div className="content__wrapper content__wrapper_loader">
                    <Loader />
                </div>
            </div>
        )
    }

    if (data && user) {
        return (
            <div className="home__wrapper">
                <div className="sidebar__wrapper">
                    <div className="sidebar__container">
                        <Logout/>
                        <div className="sidebar__user-wrapper">
                            <div className="sidebar__icon">
                                {data.client_logo ? (
                                    <img alt="Logo" src={getMediaFile(data.client_logo)}/>
                                ) : (
                                    <div className="sidebar__logo-bg bold"
                                         style={{
                                             backgroundColor: changeBg(data.id)
                                         }}>
                                        {data ? (data.client_name[0].toUpperCase()) : null}
                                    </div>
                                )}
                            </div>
                            <div className="sidebar__user">
                                <span>
                                    {data ? (data.client_name) : null}
                                </span>
                            </div>
                        </div>
                        <Menu/>
                    </div>
                </div>

                <div className="content__wrapper">
                    <Outlet/>
                </div>
            </div>
        );
    } else {
        return (
            <div className="content__wrapper content__wrapper_loader">
                <Loader/>
            </div>
        )
    }
}

export default Home;
