import axios from 'axios';
import Cookies from 'js-cookie';

// Получение CSRF токена из куки
const csrftoken = Cookies.get('csrftoken');

// Установка CSRF токена в заголовки axios по умолчанию
axios.defaults.headers.common['X-CSRFToken'] = csrftoken;

export default axios;
