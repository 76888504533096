import React, {useEffect, useState} from 'react'
import {isWorked} from "~utils/date";

const ContactsAvatar = ({data}) => {

    const [name, setName] = useState(null);
    const [photoUrl, setPhotoUrl] = useState(null);
    const [position, setPosition] = useState(null);

    useEffect(() => {
        if (data) {
            setName(`${data?.first_name || ''} ${data?.last_name || ''}`)
            setPhotoUrl(`https://toolbox.sinergium.ru${data?.pf_image || ''}`)
            setPosition(`${data?.position || ''}:` || 'Ваш менеджер:')
        }
    }, [data]);

    return (
        <div className="contacts__inner">
            <div className="contacts__image">
                <img
                    alt={name}
                    src={photoUrl}
                />
            </div>

            <div className="contacts__text">
                <div className="contacts__info">{ position }</div>
                <div className="contacts__name">{ name }</div>
                {isWorked() ? (
                    <div className="contacts__status active">На связи</div>
                ) : (
                    <div className="contacts__status">Не на связи</div>)
                }
            </div>
        </div>
    )
}

export default ContactsAvatar
