import React, { useState, useEffect, useRef, useContext, useMemo } from 'react';
import axios from '~utils/axiosSetup';
import { getCurrentDate } from '~utils/date';

import { DataContext } from "~components/DataContext";
import Button from '~components/Button/Button';
import DateRangeSelector from '~components/DateRangeSelector';

import { ReactComponent as CloseIcon } from '~img/icons/close.svg';

export default function ReportModal({ report, onClose, onSave }) {
    const { data, user } = useContext(DataContext);
    const [startDate, setStartDate] = useState(new Date(report?.period_start || new Date()));
    const [endDate, setEndDate] = useState(new Date(report?.period_end || new Date()));
    const [reportBody, setReportBody] = useState(report?.body || '');
    const [reportType, setReportType] = useState(report?.type || '');
    const [reportTypes, setReportTypes] = useState([]);
    const isInitialBodyEmpty = !report?.body;
    const selectRef = useRef(null);
    const currentDate = getCurrentDate();
    const managerId = report?.manager?.user?.id || user.id;

    const apiUrl = useMemo(() => `${window.location.origin.replace('3000', '8000')}`, []);

    useEffect(() => {
        const fetchReportTypes = async () => {
            try {
                const { data } = await axios.get(`${apiUrl}/api/report-types/`);
                setReportTypes(data);
            } catch (error) {
                console.error('Ошибка при получении типов отчётов:', error);
            }
        };

        fetchReportTypes();
    }, [apiUrl]);

    useEffect(() => {
        if (!report && reportTypes.length > 0) {
            const defaultType = reportTypes[0];
            setReportType(defaultType);
            if (isInitialBodyEmpty) {
                setReportBody(defaultType.body);
            }
        } else if (report) {
            setReportType(report.type);
            if (isInitialBodyEmpty && reportTypes.length > 0) {
                const selectedType = reportTypes.find(type => type.id.toString() === report.type);
                setReportBody(selectedType.body);
            }
        }
    }, [reportTypes, report, isInitialBodyEmpty]);

    const handleOverlayClick = (event) => {
        if (event.target === event.currentTarget) onClose();
    };

    const handleSave = async () => {
        const typeId = typeof reportType === 'object' ? reportType.id : Number(reportType);

        const updatedReport = {
            date: report?.date || currentDate,
            period_start: startDate.toLocaleDateString('en-CA'),
            period_end: endDate.toLocaleDateString('en-CA'),
            type_id: typeId,
            body: reportBody,
            manager_id:  managerId,
            client: report?.client || data.id,
        };

        try {
            const url = report ? `${apiUrl}/api/reports/${report.id}` : `${apiUrl}/api/reports/`;
            const method = report ? 'put' : 'post';

            const response = await axios({
                method,
                url,
                data: updatedReport,
                headers: { 'Content-Type': 'application/json' },
            });

            if (response.status === 200 || response.status === 201) {
                onSave(response.data);
                onClose();
            } else {
                console.error('Failed to save the report');
            }
        } catch (error) {
            console.error('Ошибка сохранения отчёта:', error.response?.data || error.message);
        }
    };

    const handleReportTypeChange = (e) => {
        const selectedType = reportTypes.find(type => type.id.toString() === e.target.value);
        setReportType(selectedType);
        if (isInitialBodyEmpty) {
            setReportBody(selectedType.body);
        }
    };

    return (
        <div className="modal modal-report">
            <div className="modal__overlay" onClick={handleOverlayClick}>
                <div className="modal__content">
                    <button className="modal__close" onClick={onClose}>
                        <CloseIcon />
                    </button>
                    <h2 className="modal__title modal-report__title">Редактировать отчёт</h2>

                    <div className="modal-report__body">
                        <div className="modal-report__option">
                            <label className="modal-report__label">
                                <span className="modal__subtitle">Тип отчёта</span>
                                <select className="modal-report__select"
                                    value={typeof reportType === 'object' ? reportType.id : reportType}
                                    onChange={handleReportTypeChange}
                                    ref={selectRef}
                                >
                                    {reportTypes.map((type) => (
                                        <option key={type.id} value={type.id}>
                                            {type.title}
                                        </option>
                                    ))}
                                </select>
                            </label>
                            <label className="modal-report__label">
                                <span className="modal__subtitle">За период</span>
                                <DateRangeSelector
                                    startDate={startDate}
                                    endDate={endDate}
                                    setStartDate={setStartDate}
                                    setEndDate={setEndDate}
                                    className="modal-report__picker"
                                />
                            </label>
                        </div>
                        <label className="modal-report__text">
                            <span className="modal__subtitle">Текст отчёта</span>
                            <textarea
                                className="modal__textarea custom-scroll"
                                value={reportBody}
                                onChange={(e) => setReportBody(e.target.value)}
                            ></textarea>
                        </label>
                    </div>

                    <div className="modal-report__footer">
                        <Button variant="main" className="modal-report__button" type="button" onClick={handleSave}>
                            Сохранить
                        </Button>
                    </div>
                </div>
            </div>
        </div>
    );
}
