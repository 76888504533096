import React from 'react'
import { ReactComponent as AvatarIcon } from '~img/reports/avatar.svg';

const Avatar = ({title, manager}) => {
	return (
		<div className='avatar__wrapper'>
			<div className="avatar__image">
				{manager?.pf_image ? (
                    <img 
                        alt="Фото менеджера" 
                        src={`https://toolbox.sinergium.ru${manager?.pf_image}`} 
                    />
                ) : (
                    <AvatarIcon />
                )}
			</div>
			<div className="avatar__text">
				<div className="avatar__title">
					{title}
				</div>
				<div className="avatar__name">
                    {manager?.user?.first_name || 'Синергиум'} {manager?.user?.last_name}
				</div>
			</div>
		</div>
	)
}

export default Avatar