import axios from 'axios';

const getApiData = async (api_url, setData, setLoading, navigate) => {
    try {
        const response = await axios.get(`${window.location.origin.replace('3000', '8000')}/api/${api_url}`);

        const data = await response.data;
        setData(data);

    } catch (error) {
        if (error.response && error.response.status === 401) {
            navigate('/login');
        } else if (api_url.includes('profile/')) {
            if (sessionStorage.getItem("currentProfile")) {
                sessionStorage.removeItem("currentProfile");
            }
        } else {
            console.error(error);
        }
    } finally {
        setLoading(false);
    }
};

export default getApiData;
