import {Link, useLocation} from "react-router-dom";
import React from "react";
import useCheckUserGroup from '~utils/useCheckUserGroup';


const Menu = () => {
    const location = useLocation();
    const isActive = (path) => location.pathname === path ? 'is-active' : '';
    const isAdmin = useCheckUserGroup('Администратор');
    const isManager = useCheckUserGroup('Менеджер');

    let userNameWrapper = document.querySelector('.sidebar__user')
    if (!!userNameWrapper) {
        if (userNameWrapper.offsetWidth < userNameWrapper.querySelector('span').offsetWidth) {
            userNameWrapper.classList.add('sidebar__user_cut')
        } else {
            userNameWrapper.classList.remove('sidebar__user_cut')
        }
    }

    return (
        <div className="sidebar__nav-wrapper">
            <ul className="sidebar__nav">
                <li className={`sidebar__li ${isActive('/')}`}>
                    <Link to="/" className="sidebar__li-title tab-1">Дашборд</Link>
                </li>
                <li className={`sidebar__li ${isActive('/tariff')}`}>
                    <Link
                        to="/tariff"
                        className="sidebar__li-title tab-2"
                    >
                        Тариф
                    </Link>
                </li>
                <li className={`sidebar__li ${isActive('/revenue')}`}>
                    <Link to="/revenue" className="sidebar__li-title tab-3">Выручка</Link>
                </li>
                <li className={`sidebar__li ${isActive('/sites')}`}>
                    <Link to="/sites" className="sidebar__li-title tab-4">Сайты</Link>
                </li>
                <li className={`sidebar__li ${isActive('/reports')}`}>
                    <Link to="/reports" className="sidebar__li-title tab-5">Отчёты</Link>
                </li>
                {(isAdmin || isManager) && (
                    <li className={`sidebar__li ${isActive('/reports-edit')}`}>
                        <Link to="/reports-edit" className="sidebar__li-title tab-5">Редактирование
                            отчётов</Link>
                    </li>
                )}
            </ul>
        </div>
    )
}

export default Menu
