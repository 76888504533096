import React, {useContext, useState, useEffect} from 'react';
import {createPortal} from "react-dom";

import {DataContext} from '~components/DataContext';
import Contacts from "~components/Contacts/Contacts";
import Button from '~components/Button/Button';
import Loader from '~components/Loader/Loader';

import {formatNumber} from "~utils/date";
import axios from "~utils/axiosSetup";
import getMediaFile from "~utils/getMediaFile";
import getApiData from "~utils/getApiData";

import editIcon from "~img/icons/edit.png";
import {ReactComponent as CloseIcon} from "~img/icons/close.svg";
import {IMaskInput} from "react-imask";


const Tariff = (param) => {
    const {data, profile, service} = useContext(DataContext);
    const [changePrice, setChangePrice] = useState(data?.tariff_price || null);
    const [selectedServices, setSelectedServices] = useState([]);
    const [price, setPrice] = useState(data?.tariff_price || null);
    const [services, setServices] = useState(profile.services || []);
    const [showModal, setShowModal] = useState(false);
    const [loading, setLoading] = useState(false);
    const [modalLoading, setModalLoading] = useState(false);


    useEffect(() => {
        if (profile?.services) {
            setSelectedServices(profile.services.map(s => s.id));
        }
    }, [profile]);

    const updateData = async () => {
        try {
            setLoading(true)
            await getApiData(`client_data/${profile.client_name}`, (data) => {
                setPrice(data[0].tariff_price)
            }, setLoading);
            await getApiData(`profile/${profile.client_name}`, (data) => {
                setServices(data[0].services)
            }, setLoading);
        } catch (error) {
            console.error("Ошибка при загрузке данных:", error);
        }
    }

    if (!data || !profile || !service || loading) {
        return (
            <div className="content__wrapper content__wrapper_loader">
                <Loader/>
            </div>
        );
    }

    let changedServices = [];

    service.forEach(service => {
        services.forEach(id => {
            if (service.id === id.id) {
                changedServices.push(service)
            }
        })
    })

    let notChangedServices = service.filter(item => !changedServices.includes(item));

    const productsIsActive = changedServices.map(item =>
        <li key={item.id}
            className="tariff__include-item"
        >
            {item.name}
        </li>
    );

    const productsIsNotActive = notChangedServices
        .map(item =>
            <div key={item.id}
                 className="tariff__not-include-item"
            >
                <div className="tariff__not-include-link">
                    {item.icon && (
                        <img
                            className="tariff__not-include-icon"
                            alt="Картинка"
                            src={getMediaFile(item.icon)}
                        />
                    )}
                    <div className="tariff__not-include-item-title">
                        {item.name}
                    </div>

                    {item.pdf_link && (
                        <a href={getMediaFile(item.pdf_link)} target="_blank" rel="noreferrer"
                           className={"link link_accent"}
                        >
                            Узнать подробнее
                        </a>
                    )}
                </div>
            </div>
        );

    const changeTariff = async (e) => {
        e.preventDefault();
        try {

            const response = await axios.post(`${window.location.origin.replace('3000', '8000')}/api/change-tariff/`, {
                changePrice,
                selectedServices,
                profile: profile.id
            });

            setModalLoading(true)
            if (response.status === 200) {
                setModalLoading(false)
            }
            onClose();
            updateData();
        } catch (error) {
            alert('Ошибка изменения данных.');
        }
    };

    const handleServiceChange = (serviceId) => {
        setSelectedServices(prev => {
            if (prev.includes(serviceId)) {
                return prev.filter(id => id !== serviceId);
            } else {
                return [...prev, serviceId];
            }
        });
    };
    const handleOverlayClick = (event) => {
        if (event.target === event.currentTarget) {
            onClose();
        }
    };
    const sortedServices = [...service].sort((a, b) => {
        const isSelectedA = selectedServices.includes(a.id);
        const isSelectedB = selectedServices.includes(b.id);
        return isSelectedA === isSelectedB ? 0 : isSelectedA ? -1 : 1;
    });

    const renderServicesCheckboxes = () => {
        return sortedServices.map(serv => (
            <div key={serv.id} className='tariff-checkbox-wrapper'>
                <input className='tariff-checkbox'
                       type="checkbox"
                       id={`service-${serv.id}`}
                       checked={selectedServices.includes(serv.id)}
                       onChange={() => handleServiceChange(serv.id)}
                />
                <label htmlFor={`service-${serv.id}`}>{serv.name}</label>
            </div>
        ));
    };

    const openModal = () => {
        setShowModal(true);
    };

    const onClose = () => {
        setShowModal(false);
    };

    return (
        <React.Fragment>
            <div className="tariff__wrapper">
                <h2 className="tariff__title">
                    Ваш тариф
                </h2>
                <div className="tariff__info-wrapper">
                    <div className="tariff__info-price-wrapper">
                        <div className="tariff__info-price-title">
                            Стоимость абонентской платы:
                        </div>
                        <div className={`tariff__info-price bold ${!price ? "bold_grey" : ''}`}>
                            {price ? `${formatNumber(price)} р.` : "0 р."}
                        </div>
                    </div>
                    <div className="tariff__info-include">
                        <div className="tariff__info-include-title">
                            Включено услуг:
                        </div>
                        <div className="tariff__info-include-count">
                            <span className={`tariff__info-include-span ${Number(productsIsActive.length) === 0 ? "bold_grey" : ''}`}> {productsIsActive.length} </span>
                            из {productsIsActive.length + productsIsNotActive.length}
                        </div>
                        <div className="tariff__info-include-line">
                            <div className="bg"></div>
                            <div className="line"
                                 style={{
                                     width: 100 / (productsIsNotActive.length + productsIsActive.length) * productsIsActive.length + '%',
                                 }}
                            >
                            </div>
                        </div>
                    </div>
                </div>
                <Contacts data={data} page={'tariff'}/>

                {productsIsActive.length > 0 &&
                    <div className="tariff__include">
                        <div className="tariff__include-title">
                            Включены услуги:
                        </div>
                        <ul className="tariff__include-items">
                            {productsIsActive}
                        </ul>
                    </div>
                }

                <div className={`tariff__not-include ${productsIsActive.length === 0 && "tariff__not-include_expanded"}`}>
                    <div className="tariff__not-include-title">
                        Не включены услуги:
                    </div>
                    <div className="tariff__not-include-items">
                        {productsIsNotActive}
                    </div>
                </div>
            </div>
            {showModal && createPortal(
                <div className='modal modal-tariff'>
                    <div className="modal__overlay" onClick={handleOverlayClick}>
                        <div className="modal__content">
                            {modalLoading ? (
                                <Loader/>
                            ) : (
                                <>
                                    <button className="modal__close" onClick={onClose}>
                                        <CloseIcon/>
                                    </button>
                                    <form className="form" onSubmit={changeTariff}>
                                        <div className="form__group form__group_name">
                                            <label className="label">Стоимость абонентской платы:</label>
                                            <div className='input-wrapper'>
                                                <IMaskInput
                                                    className="input bold"
                                                    mask={Number}
                                                    thousandsSeparator=" "
                                                    value={changePrice || ''}
                                                    onAccept={(value) => setChangePrice(value.replace(/\s+/g, ''))}
                                                />
                                                <div className='input-postfix'>
                                                    р.
                                                </div>
                                            </div>
                                        </div>
                                        <div className="form__group form__group_services">
                                            <label className="label label-checkbox">Включены
                                                услуги: {selectedServices.length} из {service.length}:</label>
                                            {renderServicesCheckboxes()}
                                        </div>
                                        <Button variant="main" className="button_save" type='submit'>Сохранить</Button>
                                    </form>
                                </>
                            )}
                        </div>
                    </div>
                </div>,
                document.getElementById('modal-root')
            )}
            {param.isEdit && (
                <Button variant="main" className='edit-tariff__button button_edit' icon={editIcon}
                        onClick={() => openModal()}>
                    Редактировать
                </Button>
            )}
        </React.Fragment>
    )
};

export default Tariff;
