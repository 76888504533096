import React, { useState, useEffect, useContext, useCallback, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { createPortal } from 'react-dom';
import axios from 'axios';

import { DataContext } from '~components/DataContext';
import Button from '~components/Button/Button';
import ReportModal from '~components/ReportModal/ReportModal';
import SearchInput from '~components/Search/Search';

import useConfirm from '~utils/useConfirm';
import getApiData from '~utils/getApiData';
import { formatDateDDMMYYYY, reportPeriod } from '~utils/date';

import { ReactComponent as TrashIcon } from '~img/reports/icon-trash.svg';
import addIcon from '~img/icons/add.svg';

const EditReports = () => {
    const { user, profile } = useContext(DataContext);
    const [reports, setReports] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [selectedReport, setSelectedReport] = useState(null);
    const [sortDirection, setSortDirection] = useState('desc');
    const [searchQuery, setSearchQuery] = useState('');
    const navigate = useNavigate();
    const { confirm, confirmComponent } = useConfirm();

    const fetchReports = useCallback(async () => {
        if (user) {
            await getApiData(`reports/profile/${profile.id}`, setReports, navigate);
        }
    }, [user, navigate, profile]);

    useEffect(() => {
        fetchReports();
    }, [fetchReports]);

    const deleteReport = useCallback(async (reportId) => {
        const confirmDelete = await confirm("Вы уверены, что хотите удалить этот отчёт?");
        if (!confirmDelete) return;

        try {
            const response = await axios.delete(`${window.location.origin.replace('3000', '8000')}/api/reports/${reportId}`);
            if (response.status === 204) {
                setReports((prevReports) => prevReports.filter(report => report.id !== reportId));
            }
        } catch (error) {
            console.error('Error deleting report:', error);
            if (error.response?.status === 401) {
                navigate('/login');
            }
        }
    }, [navigate, confirm]);

    const openModal = useCallback((report = null) => {
        setSelectedReport(report);
        setShowModal(true);
    }, []);

    const closeModal = useCallback(() => {
        setShowModal(false);
        fetchReports();
    }, [fetchReports]);

    const handleSaveReport = useCallback((updatedReport) => {
        setReports((prevReports) => {
            const reportExists = prevReports.some(report => report.id === updatedReport.id);
            if (reportExists) {
                return prevReports.map(report =>
                    report.id === updatedReport.id ? updatedReport : report
                );
            } else {
                return [...prevReports, updatedReport];
            }
        });
    }, []);

    // Функция для смены направления сортировки
    const toggleSortDirection = useCallback(() => {
        setSortDirection(prevDirection => prevDirection === 'asc' ? 'desc' : 'asc');
    }, []);

    // Сортировка отчетов по дате создания
    const sortedReports = useMemo(() => {
        return [...reports].sort((a, b) => {
            const dateA = new Date(a.date);
            const dateB = new Date(b.date);
            return sortDirection === 'asc' ? dateA - dateB : dateB - dateA;
        });
    }, [reports, sortDirection]);
    
    const filteredReports = useMemo(() => {
        return sortedReports.filter((report) => {
            const query = searchQuery.toLowerCase();
            return (
                formatDateDDMMYYYY(report.date).toLowerCase().includes(query) ||
                report.type.title.toLowerCase().includes(query) ||
                `${report?.manager?.user?.first_name || 'Синергиум'} ${report?.manager?.user?.last_name}`.toLowerCase().includes(query)
            );
        });
    }, [sortedReports, searchQuery]);

    const reportRows = useMemo(() => {
        return filteredReports.map((report) => (
            <tr key={report.id} className="edit-reports__table-row">
                <td colSpan={4} className="edit-reports__table-content" onClick={() => openModal(report)}>
                    <div className="edit-reports__table-data">
                        <span>{formatDateDDMMYYYY(report.date)}</span>
                        <span>{report.type.title}</span>
                        <span>{reportPeriod(report.period_start, report.period_end)}</span>
                        <span>{report?.manager?.user?.first_name || 'Синергиум'} {report?.manager?.user?.last_name}</span>
                    </div>
                </td>
                <td className='edit-reports__table-delete'>
                    <button type="button" className="edit-reports__table-icon-delete" onClick={() => deleteReport(report.id)}>
                        <TrashIcon />
                    </button>
                </td>
            </tr>
        ));
    }, [filteredReports, openModal, deleteReport]);

    return (
        <div className={reports.length ? 'edit-reports' : 'reports'}>
            {showModal && createPortal(
                <ReportModal 
                    report={selectedReport} 
                    onClose={closeModal} 
                    onSave={handleSaveReport}
                />,
                document.getElementById('modal-root')
            )}

            {confirmComponent}

            <h2 className={reports.length ? 'edit-reports__title' : 'reports__title'}>
                {reports.length ? 'Редактирование отчётов' : 'Отчёты'}
            </h2>

            <div className="edit-reports__header">
                <Button variant="main" className='edit-reports__button' icon={addIcon} onClick={() => openModal()}>
                    Добавить отчёт
                </Button>

                <SearchInput 
                    searchTerm={searchQuery}
                    onSearchChange={(e) => setSearchQuery(e.target.value)}
                    placeholder="Найти"
                />
            </div>

            {reports.length ? (
                <div className="edit-reports__wrapper">
                    <table className="edit-reports-table">
                        <thead className="edit-reports__table-header">
                            <tr className="edit-reports__table-header-row">
                                <th colSpan={4} className="edit-reports__table-header-content" onClick={toggleSortDirection}>
                                    <div className="edit-reports__table-header-data">
                                        <span>Дата создания {sortDirection === 'asc' ? '↑' : '↓'}</span>
                                        <span>Тип отчёта</span>
                                        <span>За период</span>
                                        <span>Автор</span>
                                    </div>
                                </th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody className="edit-reports__table-body">
                            {reportRows}
                        </tbody>
                    </table>
                </div>
            ) : (
                <div className="reports__none">Тут ещё нет отчётов</div>
            )}
        </div>
    );
};

export default EditReports;
