import React from 'react';
import DatePicker, { registerLocale } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { ru } from 'date-fns/locale';

// Регистрация русской локализации
registerLocale('ru', ru);

const DateRangePicker = ({ startDate, endDate, setStartDate, setEndDate, className }) => {
    const handleDateChange = (dates) => {
        const [start, end] = dates;
        setStartDate(start);
        setEndDate(end);
    };

    return (
        <div className={`date-range-picker ${className}`}>
            <DatePicker
                locale="ru"
                selectsRange={true}
                startDate={startDate}
                endDate={endDate}
                onChange={handleDateChange}
                placeholderText="Выбрать период отчёта"
                dateFormat="dd.MM.yyyy"
                className="date-picker"
            />
        </div>
    );
};

export default DateRangePicker;
