import { useContext, useMemo } from "react";
import { DataContext } from '~components/DataContext';

const useCheckUserGroup = (groupName) => {
    const { user } = useContext(DataContext);

    return useMemo(() => {
        if (!user || !user.groups) return false;
        return user.groups.some(item => item.name === groupName);
    }, [user, groupName]);
};

export default useCheckUserGroup;
