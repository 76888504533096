import { useCallback, useRef, useEffect } from 'react';

export const useDebounce = (fn, ms) => {
    const timeoutRef = useRef(null);

    const debouncedFn = useCallback((...args) => {
        if (timeoutRef.current) {
            clearTimeout(timeoutRef.current);
        }
        timeoutRef.current = setTimeout(() => {
            fn(...args);
        }, ms);
    }, [fn, ms]);

    // Очистка таймера при размонтировании компонента
    useEffect(() => {
        return () => {
            if (timeoutRef.current) {
                clearTimeout(timeoutRef.current);
            }
        };
    }, []);

    return debouncedFn;
};
