import React, { useState, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { DataContext } from '~components/DataContext';
import Avatar from '~components/Avatar/Avatar';
import Loader from '~components/Loader/Loader';
import Button from '~components/Button/Button';
import getApiData from '~utils/getApiData';
import { reportPeriod } from '~utils/date';
import { ReactComponent as IconCalendar } from '~img/reports/icon-calendar.svg';

const Reports = () => {
    const { user, profile } = useContext(DataContext);
    const [reports, setReports] = useState([]);
    const [selectedReport, setSelectedReport] = useState(null);
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();

    useEffect(() => {
        if (user) {
            getApiData(`reports/profile/${profile.id}`, (data) => {
                setReports(data);
                setSelectedReport(data[0]);
                setLoading(false);
            }, setLoading, navigate);
        } else {
            setLoading(false);
        }
    }, [user, navigate, profile]);

    const handleCardClick = (report) => {
        setLoading(true);
        setSelectedReport(report);
        setTimeout(() => {
            setLoading(false);
        }, 500);
    };

    if (!reports.length) {
        return (
            <div className='reports'>
                <h2 className="reports__title">Отчёты</h2>
                <div className="reports__none">
                    Скоро здесь появятся отчёты от вашего менеджера
                </div>
                <Button href="tg://resolve?domain=" variant="border" className='report__content-button'>
                    Связаться с менеджером
                </Button>
                <div className="reports__skeleton">
                    <div className="reports__skeleton-card custom-scroll">
                        <div className="reports__skeleton-card-title"></div>
                        <div className="reports__skeleton-card-text">
                            <div className="reports__skeleton-card-item"></div>
                            <div className="reports__skeleton-card-item"></div>
                            <div className="reports__skeleton-card-item"></div>
                        </div>
                    </div>
                    <div className="reports__skeleton-card">
                        <div className="reports__skeleton-card-title"></div>
                        <div className="reports__skeleton-card-text">
                            <div className="reports__skeleton-card-item"></div>
                            <div className="reports__skeleton-card-item"></div>
                            <div className="reports__skeleton-card-item"></div>
                        </div>
                    </div>
                </div>
            </div>
        )
    } else {
        return (
            <div className='reports'>
                <h2 className="reports__title">Отчёты</h2>

                <div className="reports__wrapper">
                    <div className="reports__sidebar custom-scroll">
                        {reports.map((report) => (
                            <div className={`reports__card ${selectedReport.id === report.id ? 'is-active' : ''}`}
                                key={report.id}
                                onClick={() => handleCardClick(report)}
                            >
                                <div className="reports__card-header">
                                    <Avatar manager={report.manager} title={`Ваш менеджер:`} />
                                </div>

                                <div className="reports__card-data">
                                    <div className="reports__card-icon">
                                        <IconCalendar />
                                    </div>
                                    <span className="reports__card-title">
                                        {reportPeriod(report.period_start, report.period_end)}. {report.type.title}
                                    </span>
                                </div>

                                <span className="reports__card-text">
                                    {report.body.split('\n').map((line, index) => (
                                        <React.Fragment key={index}>
                                            {line}
                                            <br />
                                        </React.Fragment>
                                    ))}
                                </span>
                            </div>
                        ))}
                    </div>

                    <div className="reports__content">
                        {loading ? (
                            <div className="reports__content-loader">
                                <Loader />
                            </div>
                        ) : (
                            selectedReport && (
                                <div>
                                    <div className="report__content-data">
                                        <div className="reports__content-icon">
                                            <IconCalendar />
                                        </div>
                                        <div className="reports__content-title">
                                            {reportPeriod(selectedReport.period_start, selectedReport.period_end)}. {selectedReport.type.title}
                                        </div>
                                    </div>

                                    <div className="report__content-text">
                                        {selectedReport.body.split('\n').map((line, index) => (
                                            <React.Fragment key={index}>
                                                {line}
                                                <br />
                                            </React.Fragment>
                                        ))}
                                    </div>

                                    <div className="report__content-footer">
                                        <Avatar manager={selectedReport.manager} title={`С уважением, ваш менеджер:`}/>
                                        <Button href="tg://resolve?domain=" variant="border" className='report__content-button'>
                                            Связаться с менеджером
                                        </Button>
                                    </div>
                                </div>
                            )
                        )}
                    </div>
                </div>
            </div>
        )
    }
}

export default Reports
