import React, { useState, useContext } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { DataContext } from '~components/DataContext';
import useCheckUserGroup from '~utils/useCheckUserGroup';

import logoutIco from '~img/logout/logout-icon.svg'


const Logout = ({hide}) => {
    const [error, setError] = useState('');
    const navigate = useNavigate();
    const { user, setData, setProfile } = useContext(DataContext);
    const isAdmin = useCheckUserGroup('Администратор');
    const isManager = useCheckUserGroup('Менеджер');

    const handleLogout = async (event) => {
        event.preventDefault();
        try {
            await axios.post(`${window.location.origin.replace('3000', '8000')}/api/logout/`);
            localStorage.removeItem('user');
            sessionStorage.removeItem('currentProfile');
            setData(null)
            setProfile(null)
            navigate('/login');
        } catch (error) {
            setError('Ошибка выхода из аккаунта');
        }
    };

    const handleClientProfile = async (event) => {
        event.preventDefault();
        try {
            sessionStorage.removeItem('currentProfile');
            setData(null)
            setProfile(null)
            navigate('/clients');
        } catch (error) {
            setError('Ошибка выхода из профиля');
        }
    };

    return (
            <div>
                <div className="logout__wrapper">
                    <button type="button" className={`logout__button ${error ? 'error' : ''}`} onClick={handleLogout}>
                        <img alt="logout-icon" className="logout__icon" src={logoutIco}/>
                    </button>

                    <span className="logout__text">
                        {!user.first_name && !user.last_name ? user.username : user.first_name + ' ' + user.last_name}
                    </span>
                </div>
                {!hide && (isAdmin || isManager) && (
                    <div>
                        <button type="button" className="logout__button"
                                onClick={handleClientProfile}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 24 24" fill="currentColor">
                                <path d="M7.82843 10.9999H20V12.9999H7.82843L13.1924 18.3638L11.7782 19.778L4 11.9999L11.7782 4.22168L13.1924 5.63589L7.82843 10.9999Z"></path>
                            </svg>
                        </button>
                    </div>
                )}
            </div>
    )
};

export default Logout;
