import React, { useContext, useEffect, useState } from "react"
import {DataContext} from "~components/DataContext";
import Button from "~components/Button/Button";
import getApiData from "~utils/getApiData";
import ContactsAvatar from "~components/Contacts/ContactsAvatar";
import Loader from "~components/Loader/Loader";

const Contacts = (props) => {
    const { data } = useContext(DataContext);
    const [employeesData, setEmployeesData] = useState(null);
    const [loading, setLoading] = useState(true);

    function hasNullValues(data) {
        return data && Object.values(data).every(value => value !== null);
    }

    useEffect(() => {
        if (data.pm && data.tl_pm) {
            getApiData(`employees_detail/${data.pm}/${data.tl_pm}`, (fetchedData) => {
                setEmployeesData(fetchedData);
                setLoading(false);
            }, setLoading);
        } else {
            setLoading(false);
        }
    }, [data.pm, data.tl_pm]);

    if (loading) {
        return (
            <div className="content__wrapper content__wrapper_loader">
                <Loader/>
            </div>
        );
    }

    const shouldShowTlPmAvatar = hasNullValues(data.tl_pm) && data.pm !== data.tl_pm;
    const shouldShowPmAvatar = hasNullValues(data.pm);

    if (!employeesData || ((!employeesData.pm && !employeesData.tl_pm) || (!employeesData.tl_pm.is_active && !employeesData.pm.is_active))) {
        return (
            <div className="contacts contacts_none">
                <div className="contacts__title">
                    На ваш проект ещё не был назначен менеджер, свяжитесь с&nbsp;ним в&nbsp;telegram
                </div>
                <Button href="tg://resolve?domain=" variant="main" className="contacts__button">
                    Написать в Телеграме
                </Button>
            </div>
        );
    }

    return (
        <div className="contacts">
            {props.page === "tariff" && (
                <div className="contacts__title">
                    Хотите изменить тариф
                    <span className="contacts__subtitle">или узнать больше?</span>
                </div>
            )}
            {props.page === "revenue" && (
                <div className="contacts__title">
                    Мы рядом
                    <span className="contacts__subtitle">и готовы ответить на все вопросы</span>
                </div>
            )}
            {props.page === "dashboard" && (
                <div className="contacts__title">
                    Всегда рядом
                </div>
            )}

            {(data.tg_corp_chat_id !== null ?
                <Button href={data.tg_corp_chat_id} target="_blank" variant="main" className="contacts__button">
                    Быстрая помощь в&nbsp;чате
                </Button>
                : 
                <Button href="tg://resolve?domain=" variant="main" className="contacts__button">
                    Быстрая помощь в&nbsp;чате
                </Button>
            )}

            <div className="contacts__wrapper">

                {props.page !== "dashboard" ? (
                    ((shouldShowPmAvatar && employeesData.pm.is_active) || (shouldShowTlPmAvatar && employeesData.tl_pm.is_active)) && (
                        <ContactsAvatar
                            data={
                                !employeesData.pm.is_active && employeesData.tl_pm.is_active
                                ? employeesData.tl_pm
                                : employeesData.pm
                            }
                        />
                    )
                ) : (
                    <>
                        {shouldShowPmAvatar && employeesData.pm.is_active && (
                            <ContactsAvatar
                                data={employeesData.pm}
                            />
                        )}
                        {(shouldShowTlPmAvatar || !shouldShowPmAvatar) && employeesData.tl_pm.is_active && (
                            <ContactsAvatar
                                data={employeesData.tl_pm}
                            />
                        )}
                    </>
                )}
            </div>
        </div>
    );
}

export default Contacts;
