import React, {useContext, useEffect, useState} from 'react';
import Button from "../../components/Button/Button";

import devIcon from '~img/sites/devIcon.svg'
import tooltip from '~img/sites/tooltip.svg'
import emptyIcon from '~img/sites/emptyIcon.svg'
import {DataContext} from "~components/DataContext";
import Loader from "~components/Loader/Loader";
import getApiData from "~utils/getApiData";

const Sites = () => {
    const {profile,} = useContext(DataContext);
    const [loading, setLoading] = useState(true);
    const [sites, setSites] = useState([]);

    useEffect(() => {
        getApiData(`sites/profile/${profile.id}`, (fetchedData) => {
            setSites(fetchedData)
            setLoading(false);
        }, setLoading);

    }, [profile]);

    let devCount = 0,
        devList = [],
        advCount = 0,
        advList = []

    sites.forEach(site => {

        if (site.production_stage !== 'Готов') {
            devCount += 1
            devList.push(site)
        } else {
            advCount += 1
            advList.push(site)
        }
    })

    function declOfNum(n, text_forms) {
        n = Math.abs(n) % 100;
        let n1 = n % 10;
        if (n > 10 && n < 20) {
            return text_forms[2];
        }
        if (n1 > 1 && n1 < 5) {
            return text_forms[1];
        }
        if (n1 === 1) {
            return text_forms[0];
        }
        return text_forms[2];
    }

    const devSites = devList.map(item =>
        <div key={item.id} className="sites__item sites__item_dev">
            <div className="sites__item-icon-wrapper">
                <div className="sites__item-icon">
                    <img alt={"Иконка разработки"} src={devIcon}/>
                </div>
                <div className="sites__item-icon-text bold ">
                    Разрабатываем для вас новый сайт
                </div>
            </div>
            <div className="sites__item-content">

                <div className="sites__item-service">
                    Услуга:
                    {item.rk.length ? (
                        <React.Fragment>
                            {item.rk.map((rk, index) =>
                                <span key={index} className="bold nobr">{rk}</span>
                            )}
                        </React.Fragment>) : (
                        <span className="bold">Нет данных</span>
                    )}
                </div>

            </div>
        </div>
    )

    const advSites = advList.map(item =>
        <div key={item.id} className="sites__item">
            <div className="sites__image-wrapper">
                {item.image ? (
                    <img className='sites__image' 
                        src={`https://toolbox.sinergium.ru/media/sites/wallpapers/${item.image}`} 
                        onError={(e) => { e.target.src = emptyIcon; e.target.className="sites__image-error"}}
                        alt="Скриншот сайта"
                    />
                ) : (
                    <img src={emptyIcon} alt="Скриншот сайта"/>
                )}
            </div>
            
            <div className="sites__item-content">
                {item.domain && (
                    <a href={`https://${item.domain}`} target="_blank" rel="noreferrer"
                        className="sites__item-title bold">
                        {item.domain}
                    </a>
                )}

                {item.rk && (
                    <div className="sites__item-service">
                        Рекламируемая услуга:
                        {item.rk.map((rk, index) =>
                            <span key={index} className="bold nobr">{rk}</span>
                        )}
                    </div>
                )}

                <div className="sites__item-time">Сайт в рекламе:
                    {item.days_in_adv ? (
                        <React.Fragment>
                            <span
                                className="bold"> {item.days_in_adv} {declOfNum(item.days_in_adv, ['день', 'дня', 'дней'])}
                            </span>
                            {item.days_in_adv < 31 && tooltip ?
                                <span className="tooltip"><img alt="Тултип" src={tooltip}/></span> : null}
                        </React.Fragment>
                    ) : (
                        <span className="bold">Нет данных</span>
                    )}
                </div>

                {(item.users || item.visits || item.time_on_site || item.depth || item.bounce) && (
                    <div className="sites__item-bottom">
                        <div className="sites__item-bottom-title bold">
                            Поведенческие показатели за неделю для&nbsp;новых посетителей:
                        </div>

                        {item.users && (
                            <div className="sites__item-bottom-item">
                                Пользователи: <span className="bold">{item.users}</span>
                            </div>
                        )}

                        {item.visits && (
                            <div className="sites__item-bottom-item">
                                Визиты:<span className="bold">{item.visits}</span>
                            </div>
                        )}

                        {item.time_on_site && (
                            <div className="sites__item-bottom-item">
                                Время на сайте:<span className="bold">{item.time_on_site}</span>
                            </div>
                        )}

                        {item.depth && (
                            <div className="sites__item-bottom-item">
                                Глубина просмотра:<span className="bold">{item.depth}</span>
                            </div>
                        )}

                        {item.bounce && (
                            <div className="sites__item-bottom-item">
                                Отказы:<span className="bold">{item.bounce}</span>
                            </div>
                        )}
                    </div>
                )}
            </div>
        </div>
    )

    return (
        <div className="sites__wrapper">
            {loading ? (
                <div className="content__wrapper content__wrapper_loader">
                    <Loader />
                </div>
            ) : (
                <React.Fragment>
                    <h2 className="sites__title bold">
                        Сайты
                    </h2>
                    <div className="sites__adv">В рекламе сайтов: <span className="bold">{advCount}</span></div>
                    <div className="sites__dev">В разработке сайтов: <span className="bold">{devCount}</span>
                    </div>
                    <div>
                        {(advCount + devCount) > 0 ? (
                            <div className="sites__items">
                                {devSites}{advSites}
                            </div>) : (
                            <div className="sites__items">
                                <div className="sites__item sites__item_empty">
                                    {emptyIcon && (
                                        <img alt={""} src={emptyIcon}/>
                                    )}
                                    <div className="sites__item-title bold">Здесь мог бы быть ваш сайт</div>
                                    <Button className="sites__item-button " href="tg://resolve?domain="
                                            variant="border">
                                        Связаться с менеджером
                                    </Button>
                                </div>
                            </div>)
                        }
                    </div>
                </React.Fragment>
            )}
        </div>
    );
}

export default Sites;
